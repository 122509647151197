<template>
  <div>
    <div v-if="deliveryRegions.length" class="position-table">
      <md-table v-model="deliveryRegions">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="ID" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell md-label="Регион" class="custom-field">
            <span>{{ item.region }}</span>
          </md-table-cell>

          <md-table-cell md-label="Стоимость" class="custom-field">
            <span>{{ item.cost }}</span>
          </md-table-cell>

          <md-table-cell>
            <div class="edit-and-delete-block">
              <md-button
                class="md-just-icon md-simple md-primary"
                :to="`/delivery-cost/${item.id}`"
              >
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">Редактировать</md-tooltip>
              </md-button>
              <md-button
                class="md-just-icon md-simple md-danger"
                @click="selectForRemove(item.id)"
              >
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="top">Удалить</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div v-else class="my-empty empty">Пусто</div>

    <ModalConfirm
      v-if="showModal"
      text="Удалить стоимость для региона?"
      @confirm="deleteDeliveryRegion"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/Modals/Confirm";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    ModalConfirm,
  },

  data() {
    return {
      selectedId: null,
      showModal: false,
    };
  },

  computed: {
    ...mapState({
      deliveryRegions: (state) => state.deliveryCost.deliveryRegions,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getDeliveryRegions();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("deliveryCost", [
      "getDeliveryRegions",
      "removeDeliveryRegion",
    ]),

    async deleteDeliveryRegion() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.removeDeliveryRegion(this.selectedId);
      if (success) {
        await this.getDeliveryRegions();
        this.selectedId = null;
        this.showModal = false;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    selectForRemove(id) {
      this.selectedId = id;
      this.showModal = true;
    },
  },
};
</script>
