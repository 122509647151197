<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Список стоимостей доставки для регионов</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <DeliveryCostTable />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import DeliveryCostTable from "@/components/Tables/DeliveryCostTable";

export default {
  components: {
    DeliveryCostTable,
  },
};
</script>
